customElements.define(
  "popover-element",
  class extends HTMLElement {
    static observedAttributes = ["description", "css"];

    constructor() {
      super();
    }

    attributeChangedCallback(_attribute, _oldValue, _newValue) {
      const description = this.getAttribute("description");
      const css = this.getAttribute("css");

      if (!description || !css) {
        return;
      }

      const linkElem = document.createElement("link");
      linkElem.setAttribute("rel", "stylesheet");
      linkElem.setAttribute("href", css);

      // Override this in the original CSS because it's causing a white block to appear.
      const style = document.createElement("style");
      style.innerText = ".v3__tooltip::before { display: none }";

      // Tooltip
      const tooltip = document.createElement("div");
      tooltip.className = "v3__tooltip";
      tooltip.innerHTML = description;
      tooltip.style.width = "206px";
      tooltip.style.padding = "8px";

      // Popover arrow
      const arrow = document.createElement("div");
      arrow.style.position = "relative";
      arrow.style.left = "100px";
      arrow.style.top = "70px";
      arrow.className = "v3--image v3--image__pointer";

      this.appendChild(linkElem);
      this.appendChild(style);
      this.appendChild(tooltip);
      this.appendChild(arrow);
    }
  },
);

/**
 * Automatically sets the document title when it changes.
 * We want elm's view to manage the title, but don't
 * want it to mange the whole document, because it
 * collides w/ chrome extensions.
 * This allows us to embed the document title within
 * the elm element.
 **/
customElements.define(
  "elm-title-element",
  class extends HTMLElement {
    static observedAttributes = ["title"];
    constructor() {
      super();
    }
    attributeChangedCallback(_attribute, _oldValue, newValue) {
      document.title = newValue;
    }
  },
);
